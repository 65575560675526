import React from 'react'

export default ({ tags }) => (
    <div className="tags">
        {tags.map(tag =>
            <div className="tag" key={tag}>
                {tag}
            </div>
        )}
    </div>
)
