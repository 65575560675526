import React from 'react'
import { Link } from 'gatsby'

export default ({ posts }) => (
    <aside className="menu">
        <p className="menu-label">
            Posts
        </p>
        <ul>
        {posts.map(({ node: { frontmatter: { title, tags }, fields: { slug } } }) =>
            <li key={title}>
                <Link to={slug} activeClassName="is-active">{ title }</Link>
            </li>
        )}
        </ul>
    </aside>
)
