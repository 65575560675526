import React from 'react'
import { graphql } from 'gatsby'
import BareLayout from '../components/BareLayout'
import TagList from '../components/TagList'
import PostList from '../components/PostList'
import SimpleChimp from '../components/SimpleChimp'

const description = 'Dev blogs tracking the progress of indie PC game Signs of the Sojourner.'

export default ({
    data: {
        markdownRemark: { html, frontmatter: { title, author, date, tags } },
        allMarkdownRemark: { edges: posts }
    },
    location
}) => (
    <BareLayout title={title} description={description} location={location}>
        <div className="is-main-content">
            <div className="columns is-gapless" style={{ marginBottom: 0 }}>
                <div className="column is-two-thirds">
                    <div className="columns section">
                        <div className="column is-one-quarter is-hidden-touch"></div>
                        <div className="column content is-three-quarters is-hidden-touch">
                            <h1 className="title">{ title }</h1>
                            <h6 className="subtitle">{ `by ${author} on ${date}` }</h6>
                            <div dangerouslySetInnerHTML={{ __html: html }}/>
                            <p></p>
                            <TagList tags={tags || []}/>
                        </div>
                        <div className="column content is-full is-hidden-desktop">
                            <h1 className="title">{ title }</h1>
                            <h6 className="subtitle">{ `by ${author} on ${date}` }</h6>
                            <div dangerouslySetInnerHTML={{ __html: html }}/>
                            <p></p>
                            <TagList tags={tags || []}/>
                        </div>
                        <div className="column is-hidden-tablet has-background-white-bis">
                            <PostList posts={posts}/>
                        </div>
                    </div>
                </div>
                <div className="column is-one-third is-hidden-mobile">
                    <div className="has-background-white-bis is-big-sidebar">
                    </div>
                    <div className="is-sidebar">
                        <section className="section">
                            <PostList posts={posts}/>
                        </section>
                    </div>
                </div>
            </div>
        </div>
        <SimpleChimp/>
    </BareLayout>
)

export const pageQuery = graphql`
    query GetPosts($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            fields {
                slug
            }
            frontmatter {
                title
                author
                date(formatString: "MMMM DD, YYYY")
                tags
            }
        }
        allMarkdownRemark(
            filter: { frontmatter: { privacy: { ne: true } } }
            limit: 10
            sort: { order: DESC, fields: [frontmatter___date] }
        ) {
            edges {
                node {
                    frontmatter {
                        title
                        tags
                    }
                    fields {
                        slug
                    }
                }
            }
        }
    }
`
